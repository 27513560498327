







import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Check extends Vue {
    @Prop({type: Boolean, default: false}) readonly value!: boolean;
}
