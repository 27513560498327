







































import {Component, Prop, Vue} from "vue-property-decorator";
import {ITab} from "@/api/IRouterTab";

@Component
export default class Tabs extends Vue {
    @Prop({type: Array}) readonly tabs!: ITab[];
    @Prop({type: Boolean, default: false}) readonly vertical!: boolean;

    activeTab: string | undefined = "";

    changeTab(key: string) {
        this.activeTab = key;
    }

    isRouterTabActive(tab: ITab) {
        return this.$route.name === tab.to ||
            ((tab.subRoutes?.filter(r => r === this.$route.name).length ?? 0) > 0) ||
            this.$route.meta.underlayName === tab.to ||
            ((tab.subRoutes?.filter(r => r === this.$route.meta.underlayName).length ?? 0) > 0);
    }

    created() {
        this.activeTab = this.tabs[0].key;
    }
}
