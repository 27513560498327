







































































































































































import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest, getQueryStringKey,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import Check from "@/components/helpers/Check.vue";
import Icon from "@/components/helpers/Icon.vue";
import IconButton from "@/components/helpers/IconButton.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {
        Pagination,
        IconButton,
        Check,
        Icon
    }
})
export default class DataTable extends Vue {
    @Model("update:selected", {type: Array, default: () => []}) readonly selected!: any[];
    @Prop({type: String, default: null}) readonly title!: string | null;
    @Prop({type: Promise, required: true}) readonly dataProvider!: Promise<IPaginatedList<any>>;
    @Prop({type: Array, required: true}) readonly columns!: ITableColumn[];
    @Prop({type: String, default: "multi"}) readonly selectedMode!: string;
    @Prop({type: Boolean, default: true}) readonly showSearch!: boolean;
    @Prop({type: Boolean, default: true}) readonly showPlus!: boolean;
    @Prop({type: Boolean, default: false}) readonly showDelete!: boolean;
    @Prop({type: Boolean, default: true}) readonly showPagination!: boolean;
    @Prop({type: Boolean, default: false}) readonly selectable!: boolean;
    @Prop({type: Boolean, default: false}) readonly autoSelect!: boolean;
    @Prop({type: Boolean, default: true}) readonly fixedHeaders!: boolean;

    @Prop({type: Object, default: null}) readonly queryProp!: IFilteredQueryRequest | null;
    @Prop({type: Number, default: 25}) readonly initialPageSize!: number;
    @Prop({type: String, default: "name"}) readonly initialOrderBy!: string;
    @Prop({type: String, default: ""}) readonly searchPlaceholder!: string;

    query: IFilteredQueryRequest = this.queryProp ?? new FilteredQueryRequest(1, this.initialPageSize, this.initialOrderBy, true, "", [], "");

    paginatedList: IPaginatedList<any> = emptyPaginatedList();
    loading = false;
    selections: any[] = this.selected;

    get searchPlaceholderVal(): string {
        return this.searchPlaceholder || this.$t("search-by-name").toString();
    }

    ariaSort(colKey: string, sortable: boolean): string {
        if (sortable) {
            if (this.query.orderBy !== colKey) return "none";
            else return this.query.orderByAsc ? "ascending" : "descending";
        } else {
            return "";
        }
    }

    sortBy(colKey: string, sortable: boolean) {
        if (sortable) {
            if (this.query.orderBy === colKey) {
                this.query.orderByAsc = !this.query.orderByAsc;
            } else {
                this.query.orderBy = colKey;
                this.query.orderByAsc = true;
            }

            this.refresh();
        }
    }

    select(item: any, specific = false) {
        if (this.selectable) {
            if (specific || !this.$listeners["row-clicked"]) {
                const index = this.selections.indexOf(item);
                if (index === -1) {
                    if (this.selectedMode === "multi")
                        this.selections.push(item);
                    else
                        this.selections = [item];
                } else {
                    if (this.selectedMode === "multi")
                        this.selections.splice(index, 1);
                }
                this.$emit("update:selected", this.selections);
            } else {
                this.$emit("row-clicked", item);
            }
        } else {
            this.$emit("row-clicked", item);
        }
    }

    middleClick(item: any) {
        this.$emit("row-middle-clicked", item);
    }

    controlClick(item: any) {
        this.$emit("row-middle-clicked", item);
    }

    @Watch("selected")
    updateSelected() {
        if (this.selected.length === 0) {
            this.selections = [];
        }
    }

    selectAll() {
        if (this.selections.length === this.paginatedList.list.length) {
            this.selections = [];
        } else {
            this.selections = Array.from(this.paginatedList.list);
        }

        this.$emit("update:selected", this.selections);
    }

    get colspan() {
        return this.columns.length + (this.selectable ? 1 : 0);
    }

    preventClick(e: Event, column: ITableColumn) {
        if (column.itemStopClick) {
            e.stopPropagation();
        }
        return e;
    }

    @Watch("dataProvider", {immediate: true, deep: true})
    onDataProviderChange() {
        const timeout = setTimeout(() => {
            this.loading = true;
        }, 500);
        this.dataProvider
            .then(data => {
                clearTimeout(timeout);
                this.loading = false;
                this.paginatedList = data;
                if (this.autoSelect && data.totalCount == 1) {
                    this.selections = [data.list[0]];
                    this.$emit("update:selected", [data.list[0]]);
                }
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-load-data").toString(), ErrorToastSettings);
                clearTimeout(timeout);
                this.loading = false;

                throw err;
            });
    }

    @Watch("query.pageSize")
    onPageSizeChange() {
        this.query.page = 1;
        this.refresh();
    }

    @Watch("query.searchTerm")
    @Watch("query.page")
    refresh() {
        if (this.query.key) {
            const searchParams = new URLSearchParams(window.location.search);
            if (this.showSearch && this.query.searchTerm)
                searchParams.set(getQueryStringKey(this.query, "search"), this.query.searchTerm);
            else
                searchParams.delete(getQueryStringKey(this.query, "search"));

            searchParams.set(getQueryStringKey(this.query, "page"), this.query.page.toString());
            searchParams.set(getQueryStringKey(this.query, "pageSize"), this.query.pageSize.toString());
            searchParams.set(getQueryStringKey(this.query, "orderBy"), this.query.orderBy);
            searchParams.set(getQueryStringKey(this.query, "orderByAsc"), this.query.orderByAsc.toString());

            const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
            history.replaceState(null, "", newRelativePathQuery);
        }

        this.$emit("query-change", this.query);
    }

    mounted() {
        this.refresh();
    }
}
