

























import {Vue, Component, Prop} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";
import dialogPolyfill from "dialog-polyfill";

@Component({
    components: {Icon}
})
export default class Modal extends Vue {
    @Prop({type: String}) readonly title!: string;
    @Prop({type: String, default: "md"}) readonly size!: string;

    done() {
        this.$emit("done");
    }

    mounted() {
        if (typeof HTMLDialogElement !== "function") {
            (this.$el.firstChild as HTMLElement).classList.remove("safari_only");
        }

        dialogPolyfill.registerDialog(this.$el.childNodes[1] as HTMLDialogElement);
        (this.$el.childNodes[1] as HTMLDialogElement).showModal();
    }
}
