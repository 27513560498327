import {Guid} from "guid-typescript";
import {postRequest} from "@/api/HttpMethods";

export const ParcelShippingApi = {
    buyPostage(clientId: Guid, parcelId: Guid, carrierId: Guid, quote: ShippingQuote): Promise<void> {
        return postRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/purchase`, {quote});
    },
};

export async function openBase64InNewTab(data: string, type: string, identifier: string) {
    let mimeType;
    let blobData;
    if (type.toUpperCase().includes("PDF")) {
        mimeType = "application/pdf";
        const byteChars = window.atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        blobData = new Uint8Array(byteNumbers);
    } else if (type.toUpperCase().includes("PNG")) {
        mimeType = "image/png";
        console.log(data);
        blobData = await (await fetch("data:" + mimeType + ";charset=utf-8;base64," + data)).blob();
    } else {
        return;
    }

    const newWindow = window.open("about:blank", identifier);
    const newBlob = new Blob([blobData], {type: mimeType});
    const objectURL = window.URL.createObjectURL(newBlob);

    if (newWindow) {
        newWindow.location.href = objectURL;

        setTimeout(function () {
            window.URL.revokeObjectURL(objectURL);
        }, 5000);
    }
}

export function openBlobInNewTab(data: Blob, identifier?: string) {
    const newWindow = window.open("about:blank", identifier ?? "name");
    const newBlob = new Blob([data], {type: "application/pdf"});
    const objectURL = window.URL.createObjectURL(newBlob);
    if (newWindow) {
        newWindow.location.href = objectURL;

        setTimeout(function () {
            window.URL.revokeObjectURL(objectURL);
        }, 5000);
    }
}

function getBaseUrl(clientId: Guid, parcelId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/parcels/${parcelId}/shipping`;
}

export interface ShippingQuote {
    serviceReference: string;
    carrierName: string;
}
