


































import {Component, Model, Prop, Vue} from "vue-property-decorator";

function paginate(
    totalItems: number,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

@Component
export default class Pagination extends Vue {
    @Model("update:value", {type: [Number], default: 1}) value!: number;
    @Prop({type: Number, default: 10}) readonly pageSize!: number;
    @Prop({type: Number, default: 1}) readonly total!: number;

    limit = 3;

    get paginationData() {
        return paginate(this.total, this.value, this.pageSize, this.limit);
    }

    get page(): number {
        return this.value;
    }

    set page(value: number) {
        this.$emit("update:value", value);
    }

    showPageButton(index: number) {
        const data = this.paginationData;

        return data.pages.includes(index + 1);
    }

    created() {
        const query = window.matchMedia("(min-width: 700px)");
        if (query.matches) {
            this.limit = 5;
        }
    }
}
