import {Guid} from "guid-typescript";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {IFilteredQueryRequest, IPaginatedList, IQueryRequest} from "@/api/Utils";
import {Client} from "@/api/v1/ClientApi";

export const TemplateApi = {
    create(clientId: Guid, template: Template): Promise<Template> {
        return postRequest(`${getBaseUrl(clientId)}`, template);
    },
    find(clientId: Guid, query: IFilteredQueryRequest): Promise<IPaginatedList<Template>> {
        return getRequest(`${getBaseUrl(clientId)}`, query);
    },
    findById(clientId: Guid, id: Guid): Promise<Template> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    preview(clientId: Guid, id: Guid): Promise<Blob|string|void> {
        return postRequest(`${getBaseUrl(clientId)}/${id}/preview`);
    },
    previewPdf(clientId: Guid, id: Guid): Promise<Blob> {
        return this.preview(clientId, id) as Promise<Blob>;
    },
    previewImage(clientId: Guid, id: Guid): Promise<string> {
        return this.preview(clientId, id) as Promise<string>;
    },
    previewEmail(clientId: Guid, id: Guid): Promise<void> {
        return this.preview(clientId, id) as Promise<void>;
    },
    duplicate(clientId: Guid, id: Guid): Promise<Template> {
        return postRequest(`${getBaseUrl(clientId)}/${id}/duplicate`);
    },
    update(clientId: Guid, id: Guid, template: Template): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, template);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    grantAccess(clientId: Guid, id: Guid, otherClientId: Guid): Promise<void> {
        return postRequest(`${getBaseUrl(clientId)}/${id}/clients`, otherClientId);
    },
    grantAccessToAll(clientId: Guid, id: Guid): Promise<void> {
        return postRequest(`${getBaseUrl(clientId)}/${id}/clients/all`);
    },
    revokeAccess(clientId: Guid, id: Guid, otherClientId: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}/clients/${otherClientId}`);
    },
    findClientsWithAccess(clientId: Guid, id: Guid, query: IQueryRequest): Promise<IPaginatedList<Client>> {
        return getRequest(`${getBaseUrl(clientId)}/${id}/clients`, query);
    },
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/templates`;
}

export interface Template {
    clientId: Guid;
    id: Guid;
    name: string;
    title: string;
    type: TemplateType;
    html: string;
    shareable: boolean;
}

export function emptyTemplate(type: TemplateType): Template {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        title: "",
        type: type,
        html: "",
        shareable: false
    };
}

export enum TemplateType {
    AddressLabel = 1,
    OrderImportedEmail = 2,
    DispatchEmail = 3,
    OrderSheet = 4
}
