export function displayDateTime(val: string | Date): string {
    const date = new Date(val);

    let result = date.toLocaleDateString();

    const timeString = date.toLocaleTimeString();

    if (timeString !== "00:00:00") {
        result += ` ${timeString}`;
    }

    return result;
}
export function displayDate(val: string | Date): string {
    const date = new Date(val);
    return date.toLocaleDateString();
}

export function capitalise(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const SuccessToastSettings = {
    autoHideDelay: 2000,
    noCloseButton: true
};

export const ErrorToastSettings = {
    autoHideDelay: 3000,
    noCloseButton: true,
    variant: "danger",
    title: "Error"
};

export interface IPaginatedList<T> {
    list: T[];
    totalCount: number;
    pageSize: number;
    page: number;
    noOfPages: number;
}

export function emptyPaginatedList<T>(): IPaginatedList<T> {
    return {
        list: [],
        totalCount: 0,
        pageSize: 25,
        page: 1,
        noOfPages: 1
    };
}

export interface IQueryRequest {
    page: number;
    pageSize: number;
    orderBy: string;
    orderByAsc: boolean;
    key: string;
    toQueryString(): string;
}

export class QueryRequest implements IQueryRequest {
    public page: number;
    public pageSize: number;
    public orderBy: string;
    public orderByAsc: boolean;
    public key = "";

    constructor(
        page = 1,
        pageSize = 25,
        orderBy = "name",
        orderByAsc = true,
        key = "",
    )
    {
        if (key) {
            this.key = key;
            const searchParams = new URLSearchParams(window.location.search);
            const pageParam = searchParams.get(getQueryStringKey(this, "page"));
            const pageSizeParam = searchParams.get(getQueryStringKey(this, "pageSize"));
            const orderByParam = searchParams.get(getQueryStringKey(this, "orderBy"));
            const orderByAscParam = searchParams.get(getQueryStringKey(this, "orderByAsc"));
            if (pageParam && !isNaN(+pageParam)) this.page = +pageParam;
            else this.page = page;
            if (pageSizeParam && !isNaN(+pageSizeParam)) this.pageSize = +pageSizeParam;
            else this.pageSize = pageSize;
            if (orderByParam) this.orderBy = orderByParam;
            else this.orderBy = orderBy;
            if (orderByAscParam) this.orderByAsc = orderByAscParam == "true";
            else this.orderByAsc = orderByAsc;
        } else {
            this.page = page;
            this.pageSize = pageSize;
            this.orderBy = orderBy;
            this.orderByAsc = orderByAsc;

            this.key = key;
        }
    }

    public toQueryString(): string {
        return `page=${this.page}&pageSize=${this.pageSize}&orderBy=${this.orderBy}&orderByAsc=${this.orderByAsc}`;
    }
}

export interface IFilteredQueryRequest extends IQueryRequest {
    searchTerm: string;
    filters: IFilter[];

    getFiltersAsSearchParams(keyed?: boolean): URLSearchParams;
}

export class FilteredQueryRequest implements IFilteredQueryRequest {
    public page: number;
    public pageSize: number;
    public orderBy: string;
    public orderByAsc: boolean;
    public searchTerm: string;
    public filters: IFilter[] = [];
    public key = "";

    constructor(
        page = 1,
        pageSize = 25,
        orderBy = "name",
        orderByAsc = true,
        searchTerm = "",
        filters: IFilter[] = [],
        key = "",
    )
    {
        if (key) {
            this.key = key;
            const searchParams = new URLSearchParams(window.location.search);
            const pageParam = searchParams.get(getQueryStringKey(this, "page"));
            const pageSizeParam = searchParams.get(getQueryStringKey(this, "pageSize"));
            const orderByParam = searchParams.get(getQueryStringKey(this, "orderBy"));
            const orderByAscParam = searchParams.get(getQueryStringKey(this, "orderByAsc"));
            if (pageParam && !isNaN(+pageParam)) this.page = +pageParam;
            else this.page = page;
            if (pageSizeParam && !isNaN(+pageSizeParam)) this.pageSize = +pageSizeParam;
            else this.pageSize = pageSize;
            if (orderByParam) this.orderBy = orderByParam;
            else this.orderBy = orderBy;
            if (orderByAscParam) this.orderByAsc = orderByAscParam == "true";
            else this.orderByAsc = orderByAsc;

            const search = searchParams.get(getQueryStringKey(this, "search"));
            if (search) this.searchTerm = search;
            else this.searchTerm = searchTerm;

            const filterParamKeys: string[] = [];
            for (const key of searchParams.keys()) {
                if (key.includes(`${this.key}.where`)) {
                    filterParamKeys.push(key);
                }
            }

            if (filterParamKeys.length > 0) {
                for (const key of filterParamKeys) {
                    const val = searchParams.get(key);
                    if (val) {
                        const splitKey = key.split(".");
                        this.filters.push({
                            name: splitKey[2],
                            op: splitKey[3] as FilterOperand,
                            value: val
                        });
                    }
                }
            } else {
                this.filters = filters;
            }
        } else {
            this.page = page;
            this.pageSize = pageSize;
            this.orderBy = orderBy;
            this.orderByAsc = orderByAsc;
            this.searchTerm = searchTerm;

            this.filters = filters;
            this.key = key;
        }
    }

    public toQueryString(): string {
        let str = `page=${this.page}&pageSize=${this.pageSize}&orderBy=${this.orderBy}&orderByAsc=${this.orderByAsc}`;
        if (this.searchTerm != "") str += `&searchTerm=${encodeURIComponent(this.searchTerm)}`;

        this.filters.forEach(f => {
            str += `&${filterToQueryString(f)}`;
        });

        return str;
    }

    public getFiltersAsSearchParams(api = false): URLSearchParams {
        const params = new URLSearchParams(api ? "" : window.location.search);

        if (!api) {
            const filterParamKeys: string[] = [];
            for (const key of params.keys()) {
                if (key.includes(`${this.key}.where`)) {
                    filterParamKeys.push(key);
                }
            }

            for (const key of filterParamKeys) {
                params.delete(key);
            }
        }

        this.filters.forEach(f => {
            params.append(`${api ? "$" : `${this.key}.`}where.${encodeURIComponent(f.name)}.${f.op ?? FilterOperand.Equals}`, `${encodeURIComponent(f.value.toString())}`);
        });

        return params;
    }
}

export function getQueryStringKey(query: IQueryRequest, name: string): string {
    return `${query.key ? `${query.key}.` : ""}${name}`;
}

export function filterToQueryString(f: IFilter): string {
    const op = f.op ?? FilterOperand.Equals;
    return `$where.${encodeURIComponent(f.name)}.${op}=${encodeURIComponent(f.value.toString())}`;
}

export interface IFilter {
    name: string;
    op?: FilterOperand;
    value: number | string | boolean;
}

export enum FilterOperand {
    Equals = "eq",
    NotEquals = "neq",
    LessThan = "lt",
    LessThanOrEquals = "lte",
    GreaterThan = "gt",
    GreaterThanOrEquals = "gte",
    Exists = "exists",
    NotExists = "nexists"
}
