




import {Vue, Component} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";

@Component({components: {Icon}})
export default class DeleteIcon extends Vue {

}
